.about {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}

.about_info {
    z-index: 1;
    padding: 25px;
}

.about_infoText {
    width: 100%;
    font-size: 30px;
}
.about_me {
    margin-top: 50px;
    padding: 25px;
}

.about_meText {
    width: 100%;
    z-index: 1;
    font-size: 20px;
}

@media (max-width: 1000px) {
    .about {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
        width: 100%;
    }

    .about_info {
        z-index: 1;
        padding: 25px;
    }

    .about_infoText {
        font-size: 20px;
    }

    .about_me {
        margin-top: 0px;
        padding: 25px;
    }

    .about_meText {
        width: 100%;
        z-index: 1;
        font-size: 15px;
    }
}

@media (max-width: 760px) {
    .about {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
    }

    .about_info {
        z-index: 1;
        padding: 25px;
    }

    .about_infoText {
        font-size: 20px;
    }

    .about_me {
        margin-top: 0px;
        padding: 25px;
    }

    .about_meText {
        width: 100%;
        z-index: 1;
        font-size: 15px;
    }
}

@media (max-width: 360px) {
    .about {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
    }

    .about_info {
        z-index: 1;
        padding: 25px;
    }

    .about_infoText {
        font-size: 20px;
    }

    .about_me {
        margin-top: 0px;
        padding: 25px;
    }

    .about_meText {
        width: 100%;
        z-index: 1;
        font-size: 15px;
    }
}
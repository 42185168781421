* {
}

.graph {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.graph_interface {
    display: flex;
    flex-direction: column;
    height: 300px;
    margin-left: 2.5%;
    margin-top: 10%;
    justify-content: center;
}

.graph_info {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    width: 100%;
}

.graph_title {
    font-size: 30px;
    margin-top: 10px;
    width: 30%;
    margin-left: 220px;
}

.graph_title_region {
    margin-top: 0px;
    font-size: 30px;
    margin-left: 220px;
    width: 25%;
}

.graph_title_maxEntrants {
    margin-top: 0px;
    font-size: 20px;
    margin-left: 220px;
    width: 25%;
}

.graph_title_pageNum {
    margin-top: 0px;
    font-size: 20px;
    margin-left: 220px;
    width: 25%;
}

.graph_image {
    margin-bottom: 0px;
}

.waiting {
    margin-top: 17.5%;
    margin-left: 23px;
}

.waiting_title {
    display: flex;
    justify-content: center;
}

.waiting_titleText {
    font-size: 70px;
}

.graph_entrieswrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
    border-radius: 10px;
    background: #009DDC;
    color: white;
    height: 100px;
}

.paginationCheckmark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
    border-radius: 10px;
    background: #009DDC;
    color: white;
    height: 100px;
    margin-top: 10px;
}

.toggleText{
    margin-left: 24%;
    margin-bottom: 4%;
}

.checkbox{
    margin-left: 45%;
}
.graph_entriesInput {
    margin-top: 10px;
    font-size: 25px;
    height: 100px;
}

.graph_entriesInputBar {
    padding: 5px;
    border: 2px solid #eee;
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
    border-radius: 10px;
    width: 85px;
    height: 50px;
    margin-top:0px;
    margin-bottom: 10px;
    margin-left: 40px;
}

.graph_entriesInputLabel {
    height: 10px;
    margin-left: 45px;
    margin-bottom: 20px;
    width: 10%;
}

.dropdownMenu_state {
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 
}

    .dropdownMenu_state a {
        background-color: #F26430;
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    }

        .dropdownMenu_state a:hover {
            background-color: #F24000;
            border: none;
            color: white;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
        }

.ddms_wrapper {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dropdownMenu_country {
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100px;
}

    .dropdownMenu_country a {
        background-color: #F26430; /* Green */
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    }

        .dropdownMenu_country a:hover {
            background-color: #F24000; /* Green */
            border: none;
            color: white;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
        }

.ddmc_wrapper {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.backButton {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    background-color: #009DDC;
    text-align: center;
    transition: all 0.2s;
    box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
    border-radius: 10px;
    width: 75px
}

.backButton:hover {
    background-color: #00BDDC;
}

.nextButton {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 10px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    background-color: #009DDC;
    text-align: center;
    transition: all 0.2s;
    box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
    border-radius: 10px;
    width: 75px;
    margin-right: 10px;
}

    .nextButton:hover {
        background-color: #00BDDC;
    }
.buttonWrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    margin-right: 5px;
    height: 50px;
}

.regionWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
}

.graph_interface_wrapper{
    display: flex;
    flex-direction: column;
    margin-left: 2.3%;
}


@media (max-width: 1000px) {
    .graph {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .graph_interface {
        display: flex;
        flex-direction: column;
        height: 150px;
        width: 200px;
        margin-top: 100px;
        justify-content: center;
    }

    .graph_info {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .graph_title {
        font-size: 25px;
        margin-top: 10px;
        width: 500px;
        margin-left: 315px;
    }

    .graph_title_region {
        font-size: 20px;
        margin-top: 10px;
        width: 500px;
        margin-left: 315px;
    }

    .graph_title_maxEntrants {
        font-size: 20px;
        margin-top: 10px;
        width: 500px;
        margin-left: 315px;
    }

    .graph_title_pageNum {
        margin-top: 0px;
        font-size: 20px;
        margin-left: 315px;
        width: 500px;
    }

    .graph_image {
        margin-bottom: 0px;
        margin-left: 0px;
        width: 100%;
    }

    .waiting {
        margin-top: 300px;
        margin-left: 25px;
    }

    .waiting_title {
        display: flex;
        justify-content: center;
    }

    .waiting_titleText {
        font-size: 70px;
    }

    .graph_entrieswrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        background: #009DDC;
        color: white;
        height: 100px;
        align-itmes: center;
    }

    .paginationCheckmark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        background: #009DDC;
        color: white;
        height: 100px;
        margin-top: 10px;
        margin-left: 48.5px;
    }

    .toggleText {
        margin-left: 24%;
        margin-bottom: 4%;
    }

    .checkbox {
        margin-left: 45%;
    }

    .graph_entriesInput {
        margin-top: 10px;
        font-size: 25px;
        height: 100px;
    }

    .graph_entriesInputBar {
        padding: 5px;
        border: 2px solid #eee;
        box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
        border-radius: 10px;
        width: 85px;
        height: 50px;
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 55px;
    }

    .graph_entriesInputLabel {
        height: 10px;
        margin-left: 60px;
        margin-bottom: 20px;
        width: 10%;
    }

    .dropdownMenu_state {
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        width:
    }

        .dropdownMenu_state a {
            background-color: #F26430;
            border: none;
            color: white;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
        }

            .dropdownMenu_state a:hover {
                background-color: #F24000;
                border: none;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
            }

    .ddms_wrapper {
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .dropdownMenu_country {
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        width: 100px;
    }

        .dropdownMenu_country a {
            background-color: #F26430; /* Green */
            border: none;
            color: white;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
        }

            .dropdownMenu_country a:hover {
                background-color: #F24000; /* Green */
                border: none;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
            }

    .ddmc_wrapper {
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .backButton {
        display: inline-block;
        padding: 0.3em 1.2em;
        margin: 0 0.3em 0.3em 0;
        border-radius: 2em;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Roboto',sans-serif;
        font-weight: 300;
        color: #FFFFFF;
        background-color: #009DDC;
        text-align: center;
        transition: all 0.2s;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        width: 75px
    }

        .backButton:hover {
            background-color: #00BDDC;
        }

    .nextButton {
        display: inline-block;
        padding: 0.3em 1.2em;
        margin: 0 0.3em 0.3em 0;
        border-radius: 10px;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Roboto',sans-serif;
        font-weight: 300;
        color: #FFFFFF;
        background-color: #009DDC;
        text-align: center;
        transition: all 0.2s;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        width: 75px;
        margin-right: 10px;
    }

        .nextButton:hover {
            background-color: #00BDDC;
        }

    .buttonWrapper {
        margin-top: 100px;
        display: flex;
        flex-direction: row-reverse;
        margin-right: 5px;
        height: 50px;
    }

    .regionWrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-evenly;
    }

    .graph_interface_wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 2.3%;
        height: 400px;
    }
}

@media (max-width: 760px) {
    .graph {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .graph_interface {
        display: flex;
        flex-direction: column;
        height: 150px;
        width: 200px;
        margin-top: 100px;
        justify-content: center;
    }

    .graph_info {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .graph_title {
        font-size: 25px;
        margin-top: 10px;
        width: 500px;
        margin-left: 300px;
    }

    .graph_title_region {
        font-size: 20px;
        margin-top: 10px;
        width: 500px;
        margin-left: 300px;
    }

    .graph_title_maxEntrants {
        font-size: 20px;
        margin-top: 10px;
        width: 500px;
        margin-left: 300px;
    }

    .graph_title_pageNum {
        margin-top: 0px;
        font-size: 20px;
        margin-left: 300px;
        width: 500px;
    }

    .graph_image {
        margin-bottom: 0px;
        margin-left: 300px;
        width: 750px;
    }

    .waiting {
        margin-top: 300px;
        margin-left: 300px;
    }

    .waiting_title {
        display: flex;
        justify-content: center;
    }

    .waiting_titleText {
        font-size: 70px;
    }

    .graph_entrieswrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        background: #009DDC;
        color: white;
        height: 100px;
        align-itmes: center;
    }

    .paginationCheckmark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        background: #009DDC;
        color: white;
        height: 100px;
        margin-top: 10px;
        margin-left: 48.5px;
    }

    .toggleText {
        margin-left: 24%;
        margin-bottom: 4%;
    }

    .checkbox {
        margin-left: 45%;
    }

    .graph_entriesInput {
        margin-top: 10px;
        font-size: 25px;
        height: 100px;
    }

    .graph_entriesInputBar {
        padding: 5px;
        border: 2px solid #eee;
        box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
        border-radius: 10px;
        width: 85px;
        height: 50px;
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 55px;
    }

    .graph_entriesInputLabel {
        height: 10px;
        margin-left: 60px;
        margin-bottom: 20px;
        width: 10%;
    }

    .dropdownMenu_state {
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        width:
    }

        .dropdownMenu_state a {
            background-color: #F26430;
            border: none;
            color: white;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
        }

            .dropdownMenu_state a:hover {
                background-color: #F24000;
                border: none;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
            }

    .ddms_wrapper {
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .dropdownMenu_country {
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        width: 100px;
    }

        .dropdownMenu_country a {
            background-color: #F26430; /* Green */
            border: none;
            color: white;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
        }

            .dropdownMenu_country a:hover {
                background-color: #F24000; /* Green */
                border: none;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
            }

    .ddmc_wrapper {
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .backButton {
        display: inline-block;
        padding: 0.3em 1.2em;
        margin: 0 0.3em 0.3em 0;
        border-radius: 2em;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Roboto',sans-serif;
        font-weight: 300;
        color: #FFFFFF;
        background-color: #009DDC;
        text-align: center;
        transition: all 0.2s;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        width: 75px
    }

        .backButton:hover {
            background-color: #00BDDC;
        }

    .nextButton {
        display: inline-block;
        padding: 0.3em 1.2em;
        margin: 0 0.3em 0.3em 0;
        border-radius: 10px;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Roboto',sans-serif;
        font-weight: 300;
        color: #FFFFFF;
        background-color: #009DDC;
        text-align: center;
        transition: all 0.2s;
        box-shadow: 0 0 15px 4px rgb(44, 114, 200, 0.5);
        border-radius: 10px;
        width: 75px;
        margin-right: 10px;
    }

        .nextButton:hover {
            background-color: #00BDDC;
        }

    .buttonWrapper {
        margin-top: 100px;
        display: flex;
        flex-direction: row-reverse;
        margin-right: 5px;
        height: 50px;
    }

    .regionWrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-evenly;
    }

    .graph_interface_wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 200px;
        height: 400px;
    }
}
.home {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.home_title {
    display: flex;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45%;
}
.card {
    width: 650px;
    height: 80px;
    background: url("https://cdn.discordapp.com/attachments/485919122404933634/862518740952416276/click_here1.png") no-repeat;
    display: inline-block;
    border: none !important;
}

.card:active {
    background: url("https://cdn.discordapp.com/attachments/485919122404933634/862518746513539102/click_here2.png") no-repeat;
}


@media (max-width: 1000px) {
    .home {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }


    .card {
        background: url("https://cdn.discordapp.com/attachments/485919122404933634/862518740952416276/click_here1.png") no-repeat;
        background-size: 600px;
        display: inline-block;
        border: none !important;
        margin-left: 200px;
        margin-top: 50px;
    }
}

@media (max-width: 760px) {
    .home {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }


    .card {
        background: url("https://cdn.discordapp.com/attachments/485919122404933634/862518740952416276/click_here1.png") no-repeat;
        background-size: 500px;
        display: inline-block;
        border: none !important;
        margin-top: 50px;
        margin-right: -300px;
    }
}